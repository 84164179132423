(<template>
  <enterprise-payment />
</template>)

<script>
  import WizardEnterprisePayment from '@/components/wizard_components/WizardEnterprisePayment';

  export default {
    components: {
      'enterprise-payment': WizardEnterprisePayment
    }
  };
</script>
