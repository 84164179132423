<template>
  <div class="ent-settings-step"
       @keydown.enter.stop.prevent="submitHandler">
    <p v-if="title"
       class="ent-settings-step__title"
       v-html="title"></p>
    <div class="ent-settings-step__content">
      <slot></slot>
      <button :disabled="btnDisabled"
              class="sk-btn sk-btn--default ent-settings-step__submit-btn"
              @click="submitHandler"
              v-html="btnText"></button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      btnText: {
        type: String,
        default: ''
      },
      btnDisabled: {
        type: Boolean
      },
      title: {
        type: String,
        default: ''
      }
    },
    methods: {
      submitHandler() {
        this.$emit('onsubmit');
      }
    }
  };
</script>

<style scoped>
  .ent-settings-step__title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 16px;
  }

  .ent-settings-step__content {
    width: 100%;
  }

  .ent-settings-step__submit-btn {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 15px;
  }
</style>
