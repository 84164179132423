(<template>
  <form-template :title="title"
                 :progress="progressActive"
                 class="ent-payment">
    <enterprise-settings-step :btn-text="stepConfig.btnText"
                              :btn-disabled="stepConfig.btnDisabled"
                              @onsubmit="stepConfig.submitHandler">
      <div class="ent-payment__step">
        <sk-select :items-list="companiesList"
                   :preselected-value="company"
                   :description="companyText"
                   :default-value="companyText"
                   :validation-name="paymentOrgNumberErrorName"
                   class="ent-payment__field"
                   @csvaluechanged="setPaymentCompany" />
        <template v-if="showAdditionalFields">
          <org-number-field :description="norwegianOrgNumberText"
                            :placeholder="norwegianOrgNumberText"
                            :preselected-value="orgNumber"
                            :country="country"
                            :validation-name="paymentOrgNumberErrorName"
                            class="ent-payment__field"
                            @fieldvaluechanged="setPaymentOrgNumber" />
        </template>
      </div>
    </enterprise-settings-step>
  </form-template>
</template>)

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
  import WizardFormTemplate from '@/components/wizard_components/WizardFormTemplate';
  import EnterpriseSettingsStep from '@/components/wizard_components/enterprise/EnterpriseSettingsStep';
  import OrgNumberField from '@/components/enterprise/form_parts/OrgNumberField';

  export default {
    components: {
      'form-template': WizardFormTemplate,
      'enterprise-settings-step': EnterpriseSettingsStep,
      'org-number-field': OrgNumberField
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['enterpriseName', 'enterpriseId']),
      ...mapState('TTWizardStore', {
        country: ({stepsData}) => stepsData.address.country,
        enterpriseOrgNumber: ({stepsData}) => stepsData.enterprise.orgNumber,
        company: ({stepsData}) => stepsData.payment.company,
        orgNumber: ({stepsData}) => stepsData.payment.orgNumber,
        // errors
        paymentOrgNumberErrorName: ({errors}) => errors.paymentOrgNumber.name
      }),
      title() { return this.$gettext('How would you like to pay for your bookings'); },
      stepConfig() {
        return {
          title: '',
          btnText: this.$gettext('Add payment method'),
          btnDisabled: this.disableAddBtn,
          submitHandler: this.sendData
        };
      },
      showAdditionalFields() { return this.company === 'someone_else'; },
      disableAddBtn() { return this.showAdditionalFields && !this.orgNumber.trim(); },
      companyText() { return this.$gettext('Bookings will be paid by'); },
      norwegianOrgNumberText() { return this.$gettext('Norwegian organisation number*'); },
      companiesList() {
        return [{
          id: this.enterpriseId,
          name: this.enterpriseName
        }, {
          id: 'someone_else',
          name: this.$gettext('Someone else')
        }];
      }
    },
    methods: {
      ...mapMutations('TTWizardStore', [
        'setPaymentCompany',
        'setPaymentOrgNumber'
      ]),
      ...mapActions('TTWizardStore', [
        'setError',
        'removeErrors'
      ]),
      validateForm() {
        this.removeErrors();
        let isValidForm = true;

        if (this.showAdditionalFields && !this.orgNumber.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'paymentOrgNumber',
            errorText: this.$gettext('Organization number is required.')
          });
        }

        return isValidForm;
      },
      sendData() {
        if (this.validateForm()) {
          if (!this.showAdditionalFields) {
            this.setPaymentOrgNumber(this.enterpriseOrgNumber);
          }
          this.progressActive = true;
          const form = new FormData();

          form.append('payment_method[org_number]', this.orgNumber);
          form.append('payment_method[method_type]', 'organization');
          form.append('payment_method[default]', true);

          this.$store.dispatch('PaymentPayoutStore/createPayment', {form}).then(() => {
            this.progressActive = false;
            this.nextStep();
          }).catch((error) => {
            this.progressActive = false;
            const orgNumberErrors = error?.data?.errors?.org_number;
            if (orgNumberErrors?.includes('Organisation number is invalid')) {
              this.setError({
                fieldName: 'orgNumber',
                errorText: this.$gettext('Organization number is not valid.')
              });
            } else {
              this.$store.dispatch('ModalStore/closeModal');
              setTimeout(() => {
                this.$store.commit('ModalStore/setModal', {
                  component: 'error-modal',
                  data: {
                    error
                  }
                });
              }, 0);
            }
          });
        }
      },
      nextStep() {
        this.$router.push(this.$makeRoute({name: 'BuyerHome'}));
      }
    },
    mounted() {
      this.setPaymentCompany(this.enterpriseId);
    }
  };
</script>

<style scoped>
  .ent-payment__step {
    margin-bottom: 70px;
    padding: 0 117px;
    text-align: left;
  }

  .ent-payment__field {
    margin-bottom: 15px;
  }
</style>
